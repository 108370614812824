import { Button, Typography } from '@mui/material'
import { devices } from 'hooks/useMediaQueries'
import styled from 'styled-components'
import { ClientSideDynamicContentWrapper } from './styledUtils'
import { useEffect, useState } from 'react'

type MainCTAProps = {
  animationDelay?: number
  children: React.ReactNode
  onClick: () => void
  id?: string
}
export function MainCTA({
  animationDelay = 0.6,
  children,
  onClick,
  id
}: MainCTAProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(true)
  }, [])

  return (
    <ButtonContainer $slideUp={{ delay: animationDelay }} isLoaded={isLoaded}>
      <ContactButton variant="contained" onClick={onClick} id={id}>
        <Typography variant="mainCTA">{children}</Typography>
      </ContactButton>
    </ButtonContainer>
  )
}

export const ContactButton = styled(Button)`
  width: 296px;
  height: 64px;

  @media ${devices.tablet} {
    width: 264px;
  }

  @media ${devices.mobile} {
    width: 100%;
  }
`
export const ButtonContainer = styled(ClientSideDynamicContentWrapper)`
  opacity: 0;
`
