import { css } from 'styled'

export const animations = {
  $fadeIn: css`
    @keyframes fadeInOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 0.4s;
  `,
  $slideUp: css`
    opacity: 0;
    ${({ $slideUp: { delay, duration }, isLoaded }) => css`
      animation: ${isLoaded === undefined || isLoaded ? `
        moveUpwards ${duration || 0.6}s normal forwards ease-in-out,
        applyOpacity ${duration || 0.6}s normal forwards ease-in
      ` : 'none'};
      animation-delay: ${isLoaded === undefined || isLoaded ? delay || 0 : 0}s;
    `}

    @keyframes moveUpwards {
      from {
        -ms-transform: translateY(200px);
        -webkit-transform: translateY(200px);
        -moz-transform: translateY(200px);
        -o-transform: translateY(200px);
        transform: translateY(200px);
      }
    }

    @keyframes applyOpacity {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `
}
