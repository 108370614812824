import Rollbar from 'rollbar'
import { wrapPage } from './root-wrapper'

// Fonts
import './src/styles/fonts.css'

const rollbar = new Rollbar({
  accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: process.env.NODE_ENV === 'production',
  environment: process.env.GATSBY_ACTIVE_ENV,
  codeVersion: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
  payload: {
    environment: process.env.GATSBY_ACTIVE_ENV,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: process.env.GATSBY_CODEBUILD_RESOLVED_SOURCE_VERSION,
        guess_uncaught_frames: true
      }
    }
  }
})

export const onClientEntry = () => {
  window.rollbar = rollbar
  window.isCTAExperimentEnabled = process.env.GATSBY_CTA_EXPERIMENT_ENABLED === 'true'
}

export const onInitialClientRender = () => {
  if (typeof window !== 'undefined' && 'scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'
  }
}

export const onRouteUpdate = ({ location }) => {
  if (location && typeof window !== 'undefined') {
    const scrollToTopRoutes = [`/services`, '/blog']
    const pathname = location.pathname

    if (scrollToTopRoutes.some((route) => pathname.startsWith(route))) {
      window.scrollTo(0, 0)
    }
  }
}

export const wrapPageElement = wrapPage
