import { useContactDialogContext } from 'context/ContactDialogContextProvider'
import { useCTAExperiment } from '../../hooks/useCTAExperiment'
import { MainCTA } from './MainCTA'

export const LetsWorkTogetherButton = ({
  text = "Let's work together",
  animationDelay = 0.6
}: LetsWorkTogetherButtonProps) => {
  const { setDialogOpen } = useContactDialogContext()
  const ctaCopy = useCTAExperiment()

  return (
    <MainCTA
      onClick={() => setDialogOpen(true)}
      animationDelay={animationDelay}
      id="workTogetherCTA"
    >
      {ctaCopy || text}
    </MainCTA>
  )
}
type LetsWorkTogetherButtonProps = {
  text?: string
  animationDelay?: number
}
