import { Link } from 'gatsby'
import styled from 'styled-components'
import { Div, P } from '../../styled/utils'
import { FooterLink } from './footerLinks'

const LinkSection = styled(Div)`
  break-inside: avoid-column;
  margin-bottom: 48px;
`

const Title = styled(P)`
  overflow: hidden;
  color: #f6f9fc;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-transform: uppercase;
`

const Path = styled(Div)`
  color: #f6f9fc;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  text-transform: capitalize;
`

export const FooterLinkSection = ({ title, paths, url }: FooterLink) => {
  return (
    <LinkSection>
      {url ? (
        <Link to={url} style={{ color: 'inherit', textDecoration: 'none' }}>
          <Title mb={21}>{title}</Title>
        </Link>
      ) : (
        <Title mb={21}>{title}</Title>
      )}
      <Path>
        {paths.map((path, index) => (
          <Div mt={12} key={index}>
            <Link
              to={path.path}
              style={{
                color: 'inherit',
                textDecoration: 'none',
                fontWeight: 400
              }}
            >
              {path.name}
            </Link>
          </Div>
        ))}
      </Path>
    </LinkSection>
  )
}
