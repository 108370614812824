import { useEffect, useState } from 'react'
import { useAnalyticsContext } from '../context/analytics/analytics.context'
import { AnalyticsEvent } from '../constants/analytics/events'

const variantMapping = {
  control: 'Contact',
  'schedule-a-call': 'Schedule a Call',
  'request-an-estimate': 'Request an Estimate',
  'lets-work-together': `Let's work together!`
} as const

const EXPERIMENT_ID = 'cta-copy'

const getRandomVariant = () => {
  const variants = Object.keys(variantMapping)
  const array = new Uint32Array(1)
  crypto.getRandomValues(array)
  const randomIndex = array[0] % variants.length
  return variants[randomIndex]
}

const storageKey = `experiment-${EXPERIMENT_ID}`

export const useCTAExperiment = () => {
  const [ctaCopy, setCtaCopy] = useState<string | null>(null)
  const { track } = useAnalyticsContext()
  
  const isBrowser = typeof window !== 'undefined'

  const initExperiment = () => {
    const variant = getRandomVariant()
    localStorage.setItem(storageKey, variant)

    track(AnalyticsEvent.USER_JOINED_CTA_COHORT, {
      experiment_id: EXPERIMENT_ID,
      variant,
      variant_copy: variantMapping[variant as keyof typeof variantMapping]
    })

    return variant
  }

  const endExperiment = (variant: string) => {
    track(AnalyticsEvent.USER_LEFT_CTA_COHORT, {
      experiment_id: EXPERIMENT_ID,
      variant,
      variant_copy: variantMapping[variant as keyof typeof variantMapping]
    })
    localStorage.removeItem(storageKey)
  }
  
  useEffect(() => {
    if (!isBrowser || !track) return

    try {
      const isExperimentEnabled = window.isCTAExperimentEnabled

      if (!isExperimentEnabled) {
        const variant = localStorage.getItem(storageKey)
        
        if (variant && variantMapping[variant as keyof typeof variantMapping]) {
          endExperiment(variant)
        }
        setCtaCopy(null)
        return
      }

      let variant = localStorage.getItem(storageKey)
      const isNewUser = !variant

      if (isNewUser) {
        variant = initExperiment()
      }

      if (variant) {
        const copy = variantMapping[variant as keyof typeof variantMapping]
        setCtaCopy(copy)
      }
    } catch (error) {
      console.error('Error in CTA experiment:', error)
      setCtaCopy(null)
    }
  }, [track, isBrowser])

  return isBrowser && window.isCTAExperimentEnabled ? ctaCopy : null
}